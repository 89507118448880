import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledAnimatedIconsGridComponent = styled.div`
  max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .icons-container {
    width: 100%;
    justify-content: center;
    display: flex;
    gap: 14px;

    .icon-container {
      width: 56px;
      height: 56px;
    }
  }
`;
