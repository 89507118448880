import styled from "@emotion/styled";
export const StyledAnimatedIcon = styled.div`
opacity: 0;
transform: translateY(-${(props)=>props.initialOffset}px);

    &.fall-in-animation {
        animation: fall-in ${(props)=>props.animationDuration}s ease-in-out forwards;
    }

  }

  @keyframes fall-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
`;
